/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Talking Treaties Spectacle was an outdoor pageant that explored the treaty history of the Toronto area through words, song, movement, puppetry and spectacle. The mobile performance was co-created with a team of diverse community artists, bringing together oral history, petition texts to the crown, and arts based research to an outdoor audiences with rhythmic delivery. The spectacle moves around the tightly scripted one act play “Symbolically United” addressing the marriage of Molly and William Johnson on the eve of the Treaty of Niagara."), "\n", React.createElement(_components.p, null, "The Spectacle premiered at Fort York’s Indigenous Arts Festival in June 2017, alongside companion composition Underneath the Concrete, as the culminating events of Jumblies’ 7-week ", React.createElement(_components.a, {
    href: "http://www.jumbliestheatre.org/jumblies/current-projects/touching-ground"
  }, "Touching Ground Festival"), "."), "\n", React.createElement(_components.p, null, "In 2018 the Spectacle was remounted for a longer run of 6 shows, with an all-Indigenous professional cast, performing alongside diverse community singers and performers."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
